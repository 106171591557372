import Vue from 'vue'
import Vuex from 'vuex'
import decode from 'jwt-decode'
import router from '../router/index'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    usuario: null,

    metodoPagoStore: [],
    formaPagoStore: [],
    monedaStore: [],
    listClienteStore: [],
    listRegimenFiscalStore: [],
    usoCfdiStore: [],
    listaEmpresasStore: [],

    empresaStore: {
      idEmpresa: 0,
      rfc: '',
      nombreEmpresa: '',
      empresa: '',
      codigoPostal: '',
      regimenFiscal: { regimenFiscal: '' },
      usoCfdi: { usoCfdi: '' },
      correos: []
    },

    empresaEmisoraStore: {
      empresa: '',
      nombreComercial: ''
    },

    listFacturaStore: [],
    facturaStore: {
      idFactura: 0,
      folioFiscal: '',
      serie: '',
      folio: '',
      fecha: null,
      formaPago: {},
      condicionesPago: '',
      subTotal: 0,
      descuento: 0,
      moneda: {
        id: 100,
        clave: 'MXN',
        descripcion: 'Peso Mexicano',
        moneda: 'MXN | Peso Mexicano'
      },
      tipoCambio: 0,
      total: 0,
      tipoComprobante: 'I',
      tipoComprobanteInterno: 'Factura',
      metodoPago: {},
      empresa: {
        empresa: ''
      },
      cliente: {
        rfc: '',
        nombre: '',
        cliente: '',
        usoCfdi: {},
        regimenFiscal: { regimenFiscal: '' }
      },
      rfc: '',
      nombre: '',
      atn: '',
      notas: '',
      estatus: 'Sin Timbrar',
      usuarioCreador: '',
      usuarioModificador: '',
      usuarioAutoriza: '',
      conceptos: [],
      impuestos: {
        traslados: [],
        retenciones: [],
      },
      cfdiRelacionados: [
        {
          tipoRelacion: {},
          cfdiRelacionados: [],
        }
      ],
      timbreFiscalDigital: {
        folioFiscal: '',
        fechaTimbrado: new Date(),
        selloCFDI: { base64: '' },
        noCertificado: '',
        noCertificadoSAT: '',
        selloSAT: { base64: '' },
        cadenaOriginal: '',
        serie: '',
        folio: '',
        Error: '',
        archivoXml: '',
      }
    },
    correoStore: {
      nombreCorreo: '',
      correo: '',
      password: '',
      puerto: '',
      host: '',
    },
  },
  getters: {
  },
  mutations: {
    setToken(state, token) {
      state.token = token
    },
    setUsuario(state, usuario) {
      state.usuario = usuario
    }
  },
  actions: {
    guardarToken({ commit }, token) {
      commit("setToken", token)
      commit("setUsuario", decode(token))
      localStorage.setItem("token", token)
    },
    autoLogin({ commit }) {
      let token = localStorage.getItem("token")
      if (token) {
        commit("setToken", token)
        commit("setUsuario", decode(token))
        router.push({ name: 'HomeView' })
      }
    },
    salir({ commit }) {
      commit("setToken", null)
      commit("setUsuario", null)
      localStorage.removeItem("token")
      router.push({ name: 'Login' })
    }
  },
  modules: {
  }
})

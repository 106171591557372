<template>
    <div>
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: .5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>

        <Card>
            <!-- TITULO CARD -->
            <template #title>
                <div class="flex justify-content-between flex-wrap pb-2">
                    <Button icon="pi pi-times" class="p-button-rounded p-button-danger " @click="cierraVentana()" />
                    EMPRESA/CLIENTE
                    <Button icon="pi pi-save" class="p-button-rounded p-button-success " @click="guardarOactualizar()" />
                </div>
                <hr size=7 color="#ED6421">
            </template>
            <template #content>
                <ScrollTop />
                <ScrollPanel style="height: 520px">
                    <div class="p-d-flex justify-center p-fluid grid formgrid">
                        <!-- RFC -->
                        <div class="field col-12 md:col-6">
                            <span class="p-float-label">
                                <span class="p-float-label">
                                    <InputText maxlength="13" v-model="empresa.rfc" @change="validaRegimen" id="rfc"
                                        type="text" />
                                    <label for="rfc">RFC</label>
                                </span>
                            </span>
                        </div>
                        <!-- NOMBRE -->
                        <div class="field col-12 md:col-6">
                            <span class="p-float-label">
                                <span class="p-float-label">
                                    <InputText id="cp" v-model="empresa.codigoPostal" maxlength="5" type="number" />
                                    <label for="cp">Código Postal</label>
                                </span>
                            </span>
                        </div>
                        <!-- CODIGO POSTAL -->
                        <div class="field col-12">
                            <span class="p-float-label">
                                <span class="p-float-label">
                                    <InputText v-model="empresa.nombreEmpresa" id="nombreE" type="text" />
                                    <label for="nombreE">Nombre</label>
                                </span>
                            </span>
                        </div>
                        <!-- USO DE CFDI -->
                        <div class="field col-12">
                            <span class="p-float-label">
                                <Dropdown style="text-align: left" v-model="empresa.usoCfdi" :options="itemsUsoCDFI"
                                    optionLabel="usoCfdi" />
                                <label>Uso de CFDI</label>
                            </span>
                        </div>
                        <!-- REGIMEN FISCAL -->
                        <div class="justify-left field col-12">
                            <span class="p-float-label">
                                <Dropdown v-model="empresa.regimenFiscal" style="text-align: left"
                                    :options="itemsRegimenFiscal" optionLabel="regimenFiscal" />
                                <label>Régimen Fiscal</label>
                            </span>
                        </div>
                        <div class="field mr-0 pr-0 col-12 text-center mt-12">
                            <h1 style="background: #241B5E; color: white; font-size: 16px; font-family: arial;">
                                CORREOS
                            </h1>
                        </div>
                        <!-- NOMBRE DEL CORREO -->
                        <div class="justify-right field col-6">
                            <span class="p-float-label">
                                <InputText v-model="nombreCorreo" style="text-align: left" type="text" />
                                <label>Nombre</label>
                            </span>
                        </div>
                        <!-- CORREO -->
                        <div class="justify-right field col-6">
                            <div class="p-inputgroup">
                                <InputText v-model="correo" placeholder="Correo" @keydown.enter="pushCorreo()" />
                                <Button icon="pi pi-plus " @click="pushCorreo()" style=" color: white;"
                                    class="p-button-success" />
                            </div>
                        </div>
                        <InlineMessage style="font-size: 10px;" severity="info">Para agregar una lista de correos ingrese la
                            información en los
                            campos 'Nombre' y 'Correo', despues precione en el botón verde para agregarlos en una lista,
                            repita estos pasos con los correos que desea tener la Emprea o Cliente.
                        </InlineMessage>
                        <!-- TABLA DE CORREOS -->
                        <div class="justify-right field col-12">
                            <DataTable :value="empresa.correos" responsiveLayout="scroll">
                                <Column :bodyStyle="{ 'text-align': 'center', overflow: 'visible' }" :exportable="false"
                                    :styles="{ 'min-width': '1rem' }">
                                    <template #body="slotProps">
                                        <Button icon="pi pi-trash" style="width:35px; height:35px"
                                            class="p-button-rounded p-button-danger"
                                            @click="eliminarCorreo(slotProps.data)" />
                                    </template>
                                </Column>
                                <Column field="nombre" header="Nombre"></Column>
                                <Column field="correo" header="Correo"></Column>
                            </DataTable>
                        </div>
                    </div>
                    <ScrollTop target="parent" :threshold="100" class="custom-scrolltop" icon="pi pi-arrow-up" />
                </ScrollPanel>
            </template>
        </Card>
    </div>
</template>
<script>
import axios from 'axios'
import { FilterMatchMode, FilterOperator } from 'primevue/api/';

export default {
    components: {},

    data() {
        return {
            loading: false,
            loadingMensaje: '',
            filteredCliente: null,
            dialogoNuevoCliente: false,

            editedIndex: -1,

            itemsPermSCT: [],

            nombreCorreo: '',
            correo: ''
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        itemsUsoCDFI() {
            return this.$store.state.usoCfdiStore
        },
        itemsRegimenFiscal() {
            return this.$store.state.listRegimenFiscalStore
        },
        empresa() {
            return this.$store.state.empresaStore
        },
        empresaInicial() {
            return this.$store.state.empresaEmisoraStore
        }
    },
    created() {
    },
    methods: {
        guardarOactualizar() {
            if (this.empresa.idEmpresa == 0) {
                this.postEmpresa()
                console.log('guardar')
            } else {
                this.putCliente()
                console.log('editar')

            }
        },
        async postEmpresa() {
            this.loading = true;
            this.loadingMensaje = 'Guardando empresa, espere...'
            console.log(this.empresa)
            try {
                let response = await axios.post('Empresas/GuardarEmpresa/' + this.token.empresaBase, this.empresa)
                console.log(response.data)

                this.loading = false
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Empresa guardado con exito.', life: 3000 });

                this.empresa.idEmpresa = response.data
                this.$store.state.listaEmpresasStore.push(this.empresa)
                this.cierraVentana()

            } catch (error) {
                console.log(error)
                this.loading = false
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al guardar.', life: 3000 });
            }
        },
        async putCliente() {
            let objEmpresa = { ...this.empresa }

            this.loading = true;
            this.loadingMensaje = 'Actualizando cliente, espere...'

            try {
                let response = await axios.put('Empresas/PutEmprpesas/' + this.token.empresaBase, objEmpresa)
                // BUSCAMOS EL INDICE
                let indice = this.$store.state.listaEmpresasStore.findIndex(x => x.idEmpresa === objEmpresa.idEmpresa);
                Object.assign(this.$store.state.listaEmpresasStore[indice], objEmpresa)
                this.$store.state.empresaStore.idEmpresa = -1;
                this.cierraVentana()
            } catch (error) {
                console.log(error)
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al guardar, intente nuevamente', life: 5000 });
                this.loading = false;
            }
        },
        validaRegimen() {
            console.log(this.empresa.rfc)
            if (this.empresa.rfc.length === 13) {
                console.log('fisica')
                return this.itemsRegimenFiscal.filter(regimen => regimen.fisica === 'Sí');
            }
            if (this.empresa.rfc.length === 12) {
                console.log('moral')
                return this.itemsRegimenFiscal.filter(regimen => regimen.moral === 'Sí');
            }
        },

        cierraVentana() {
            this.$emit('cierraVentana')
            this.$store.state.empresaStore.idEmpresa = -1;

        },

        //CORREO
        pushCorreo() {
            let objeto = {
                nombre: this.nombreCorreo,
                correo: this.correo
            }
            this.empresa.correos.push(objeto)

            this.nombreCorreo = ''
            this.correo = ''
        },

        eliminarCorreo(data) {
            let editedIndexCorreo = this.empresa.correos.indexOf(data)
            this.empresa.correos.splice(editedIndexCorreo, 1)
        },
    },
}
</script>
<style>
.p-inline-message .p-inline-message-text {
    font-size: 12px;
}
</style>
<template>
    <div>
        <div class="text-right pt-4 pr-5 ">
            <Button icon="mdi mdi-youtube" @click="displayPosition = true"
                style="font-size: 26px; width: 40px;height: 40px;" class="p-button-rounded mr-1"
                v-tooltip.bottom="'Tutorial'" />
            <Button icon="mdi mdi-information" @click="toggle" style="font-size: 26px; width: 40px;height: 40px;"
                class="p-button-rounded" v-tooltip.bottom="'Ayuda'" />
        </div>
        <div class="padre">
            <OverlayPanel ref="op" appendTo="body" class="over" :showCloseIcon="false" id="overlay_panel">
                <Message :closable="false" severity="info">Para soporte técnico o más información, comunícate con nosotros
                    al
                    teléfono (222 622 6540) o escríbenos al correo (contago.ventas@gmail.com).</Message>
            </OverlayPanel>
            <Dialog header=" " :visible.sync="displayPosition" position="bottomright" :modal="true">
                <iframe style="width: 400px; height: 260px;" src="https://www.youtube.com/embed/dxWl6QSOb3c?autoplay=1"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen></iframe>
            </Dialog>
            <!-- DIALOGO DE LOADING -->
            <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
                <ProgressBar mode="indeterminate" style="height: .5em" />
                <span>{{ loadingMensaje }}</span>
            </Dialog>

            <div v-if="loadingInicio == 1"
                style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
                <ProgressSpinner style="vertical-align:middle;" />
            </div>

            <div class="p-d-flex">
                <Toast />
            </div>

            <!-- CREAR CUENTA -->
            <div class="surface-card md:mt-8 p-4  shadow-2 border-round w-full lg:w-5" v-if="step == 2">
                <div class="text-center mb-5">
                    <img src="../assets/contago_kiosco.jpg" alt="Image" height="100" class="">
                    <div class="text-900 text-3xl font-medium mb-3">Nuevo Usuario</div>
                </div>
                <div class="p-fluid grid text-center justify-center">
                    <!-- NOMBRE -->
                    <div class="field col-12 md:col-4">
                        <span class="p-float-label">
                            <span class="p-float-label">
                                <InputText id="nombre" type="text" v-model="obj.nombre" />
                                <label for="nombre">Nombre</label>
                            </span>
                        </span>
                    </div>
                    <!-- PRIMER APELLIDO -->
                    <div class="field col-12 md:col-4">
                        <span class="p-float-label">
                            <span class="p-float-label">
                                <InputText id="pirmerApellido" type="text" v-model="obj.primerApellido" />
                                <label for="pirmerApellido">Apellido Paterno</label>
                            </span>
                        </span>
                    </div>
                    <!-- SEGUNDO APELLIDO -->
                    <div class="field col-12 md:col-4">
                        <span class="p-float-label">
                            <span class="p-float-label">
                                <InputText id="segundoApellido" type="text" v-model="obj.segundoApellido" />
                                <label for="segundoApellido">Apellido Materno</label>
                            </span>
                        </span>
                    </div>
                    <!-- USUARIO-->
                    <div class="field col-12 md:col-4">
                        <span class="p-float-label">
                            <InputText @input="handleInput($event)" maxlength="13" id="usuario" type="text"
                                v-model="obj.usuario" />
                            <label for="usuario">Usuario</label>
                        </span>
                    </div>
                    <!-- PIN -->
                    <div class="justify-right field col-12 md:col-4">
                        <span class="p-float-label">
                            <InputMask mask="9999" v-model="obj.pin" />
                            <label>PIN</label>
                        </span>
                    </div>
                    <!-- FECHA DE NACIMIENTO -->
                    <div class="field col-12 md:col-4">
                        <span class="p-float-label">
                            <Calendar v-model="obj.fechaNacimiento" dateFormat="dd-MM-yy" />
                            <label>Fecha de Nacimiento</label>
                        </span>
                    </div>
                    <!-- TELEFONO-->
                    <div class="field col-12 md:col-3">
                        <span class="p-float-label">
                            <InputText id="telefono" type="text" v-model="obj.telefono" />
                            <label for="telefono">Teléfono</label>
                        </span>
                    </div>
                    <!-- CORREO -->
                    <div class="field col-12 md:col-9">
                        <span class="p-float-label">
                            <InputText id="correo" type="text" v-model="obj.correo" />
                            <label for="correo">Correo</label>
                        </span>
                    </div>
                    <div class="field col-6 md:col-9 text-left">
                        <Button icon="mdi mdi-arrow-left-bold" @click="step = 1" class="p-button-rounded" />
                    </div>
                    <div class="field col-6 md:col-3">
                        <Button @click="guardarUsuario()" label="Registrar" icon="pi pi-user" class="w-full"></Button>
                    </div>
                </div>
            </div>

            <!-- LOGIN -->
            <div class="surface-card md:mt-8 p-4 xl:shadow-2 border-round w-full lg:w-3" v-if="step == 1">
                <div class="text-center mb-5">
                    <img src="../assets/contago_kiosco.jpg" alt="Image" height="90" class="mb-3">
                    <div class="text-900 text-3xl font-medium mb-3">Iniciar Sesión</div>
                    <span class="text-600 font-medium line-height-3">¿No tiene una cuenta?</span>
                    <a class="font-medium no-underline ml-2 text-blue-500 cursor-pointer"
                        @click="abrirDialogNuevoUsuario">Crear
                        Cuenta.</a>
                </div>
                <div>
                    <div class="text-center mt-2 p-fluid grid">
                        <div class="field col-12 m-0">
                            <span class="p-float-label">
                                <InputText v-model="nombreU" style="text-transform: uppercase" />
                                <label>Usuario</label>
                            </span>
                        </div>
                        <div class="  col-12">
                            <div style="display: flex; justify-content: center;">
                                <!-- <div class="text-center justify-center"> -->
                                <v-otp-input ref="otpInput" input-classes="otp-input" separator="-" :num-inputs="4"
                                    :should-auto-focus="true" v-model="password" :is-input-num="true"
                                    @on-change="handleOnChange" @on-complete="handleOnComplete" type="password"
                                    input-type="password" />
                            </div>
                            <!-- </div> -->
                        </div>
                        <div class="field col-12">
                            <a class="font-medium no-underline text-blue-500 cursor-pointer" @click="step = 3">¿Olvido su
                                contraseña?</a>
                        </div>
                        <div class="field col-12">
                            <span class="p-float-label">
                                <div v-if="loadingEmpresa == 1">
                                    <ProgressBar mode="indeterminate" style="height: .5em" />
                                </div>
                                <Dropdown autoWidth="false" class="dropdown" dataKey="nombreBase"
                                    placeholder="Seleccione su empresa" v-model="selectEmpresa" :options="itemsEmpresa"
                                    optionLabel="nombre_e" autofocus />
                            </span>
                        </div>
                    </div>
                    <Button @click="ingresar()" label="Iniciar Sesión" icon="pi pi-user" class="w-full"></Button>
                </div>
            </div>

            <!-- RECUPERAR CONTRASEÑA -->
            <div class="surface-card md:mt-8 p-4 shadow-2 border-round w-full lg:w-4" v-if="step == 3">
                <div class="text-center mb-5">
                    <img src="../assets/contago_kiosco.jpg" alt="Image" height="100" class="">
                    <div class="text-900 text-3xl font-medium mb-3"></div>
                    <span class="text-600 text-left font-medium line-height-3">Ingresa tu nombre de usuario, y recibira un
                        correo.</span>
                </div>
                <div class="p-fluid grid text-center justify-center">
                    <!-- USUARIO -->
                    <div class="field col-12">
                        <span class="p-float-label">
                            <span class="p-float-label">
                                <InputText id="usuario" type="text" v-model="nombreUsuario" maxlength="13" />
                                <label for="usuario">Nombre de Usuario</label>
                            </span>
                        </span>
                    </div>
                    <div class="field col-8 text-left">
                        <Button icon="mdi mdi-arrow-left-bold" @click="step = 1" class="p-button-rounded" />
                    </div>
                    <div class="field col-4">
                        <Button @click="enviarCorreo()" label="Enviar" icon="pi pi-send" class="w-full"></Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
export default {
    components: {},
    data() {
        return {
            nombreU: "",
            password: "",

            selectEmpresa: {},
            itemsEmpresa: [],

            idUsuario: null,
            nombreUsuario: null,
            rolUsuario: null,
            empresa: null,
            empresaBase: null,
            rfc: "",
            firma: null,

            loadingEmpresa: 0,
            loadingInicio: 0,

            dialogoNuevoUsuario: false,
            step: 1,
            obj: {
                rfc: '',
                razonSocial: '',
                nombre: '',
                primerApellido: '',
                segundoApellido: '',
                usuario: '',
                pin: '',
                telefono: '',
                correo: '',
                fechaNacimiento: new Date()
            },
            loading: false,
            loadingMensaje: "",
            displayPosition: true
        };
    },
    methods: {
        toggle(event) {
            this.$refs.op.toggle(event);
        },
        handleInput(event) {
            console.log(event)
            const valueWithoutSpaces = event.replace(/\s/g, '');
            this.obj.usuario = valueWithoutSpaces || '';
        },
        async enviarCorreo() {
            if (this.nombreUsuario == '') {
                this.$toast.add({ severity: 'info', summary: 'Info', detail: 'Ingrese el nombre del Usuario.', life: 3000 });
                return
            }
            this.loading = true;
            this.loadingMensaje = 'Espere...'
            try {
                let response = await axios.post('Registro/RecuperarContraseña/' + this.nombreUsuario)
                console.log(response.data)
                this.loading = false;

                this.$toast.add({ severity: 'success', summary: 'Correcto', detail: 'Se ha enviado un correo de recuperación de contraseña.', life: 3000 });
                this.step = 1
                this.nombreUsuario = ''
            } catch (error) {
                console.log(error)
                this.loading = false;
                this.$toast.add({ severity: 'error', summary: 'Error', detail: error.response.data, life: 5000 });
            }
        },
        async guardarUsuario() {
            console.log(this.obj)

            if (this.obj.nombre == '') {
                this.$toast.add({ severity: 'info', summary: 'Info', detail: 'El campo Nombre no debe estar vacio.', life: 3000 });
                return
            }
            if (this.obj.primerApellido == '') {
                this.$toast.add({ severity: 'info', summary: 'Info', detail: 'El campo Primer Apellido no debe estar vacio.', life: 3000 });
                return
            }
            if (this.obj.usuario == '') {
                this.$toast.add({ severity: 'info', summary: 'Info', detail: 'El campo Usuario no debe estar vacio.', life: 3000 });
                return
            }
            if (this.obj.pin == '') {
                this.$toast.add({ severity: 'info', summary: 'Info', detail: 'El campo Pin no debe estar vacio.', life: 3000 });
                return
            }
            if (this.obj.telefono == '') {
                this.$toast.add({ severity: 'info', summary: 'Info', detail: 'El campo Teléfono no debe estar vacio.', life: 3000 });
                return
            }
            if (this.obj.correo == '') {
                this.$toast.add({ severity: 'info', summary: 'Info', detail: 'El campo Correo no debe estar vacio.', life: 3000 });
                return
            }
            this.loading = true;
            this.loadingMensaje = 'Creando cuenta, espere...'
            try {
                let response = await axios.post('Registro/GuardarUsuario', this.obj)
                console.log(response.data)
                this.loading = false;

                this.$toast.add({ severity: 'success', summary: 'Correcto', detail: 'Se registro exitosamente.', life: 3000 });

                this.step = 1

                this.obj.rfc = '',
                    this.obj.razonSocial = '',
                    this.obj.nombre = '',
                    this.obj.primerApellido = '',
                    this.obj.segundoApellido = '',
                    this.obj.usuario = '',
                    this.obj.pin = '',
                    this.obj.telefono = '',
                    this.obj.correo = '',
                    this.obj.fechaNacimiento = new Date()

            } catch (error) {
                console.log(error)
                this.loading = false;
                this.$toast.add({ severity: 'error', summary: 'Error', detail: error.response.data, life: 5000 });
            }
        },

        abrirDialogNuevoUsuario() {
            this.step = 2
        },
        async validarUsuario() {
            this.loadingEmpresa = 1
            if (this.nombreU == "") {
                this.$toast.add({ severity: "error", summary: "Error", detail: "Ingrese el usuario", life: 3000, });
                return;
            }
            if (this.password == "") {
                this.$toast.add({ severity: "error", summary: "Error Message", detail: "Ingrese la contraseña", life: 3000, });

                return;
            }
            //VALIDAMOS QUE EL USUARIO SEA VALIDO
            try {
                let respuesta = await axios.post("https://api-framework.contago.com.mx/api/Usuarios/ValidaAsync", { nombre: this.nombreU, password: this.password, }
                );
                this.idUsuario = respuesta.data.idUsuario_;
                this.rolUsuario = respuesta.data.rol_;
                this.firma = respuesta.data.firma_;
                this.rfc = respuesta.data.rfc_;
                this.correo = respuesta.data.correo_;
                this.listaEmpresas(respuesta.data.idUsuario_);
                this.nombreUsuario = respuesta.data.nombre_;
                this.loadingEmpresa = 0
                console.log(respuesta.data);
            } catch (err) {
                console.log(err.response.status);
                this.loadingEmpresa = 0

                if (err.response.status == 400) {
                    this.$toast.add({ severity: "error", summary: "Error", detail: "Usuario inexistente", life: 3000, });
                } else if (err.response.status == 404) {
                    this.$toast.add({ severity: "error", summary: "Error", detail: "Contraseña incorrecta", life: 3000, });
                } else if (err.response.status == 401) {
                    this.$toast.add({ severity: "error", summary: "Error", detail: "El usuario ya cuenta con una sesión iniciada", life: 3000, });
                } else {
                    this.$toast.add({ severity: "error", summary: "Error", detail: "Datos incorrectos, verifique su usuario y contraseña", life: 3000, });
                }
            }
        },
        ingresar() {
            this.loadingInicio = 1
            this.empresa = this.selectEmpresa.nombre_e;
            this.empresaBase = this.selectEmpresa.nombreBase;
            this.rfc = this.selectEmpresa.rfc;

            if (!this.selectEmpresa.nombre_e) {
                this.$toast.add({ severity: "error", summary: "Error", detail: "Seleccione una empresa de la lista", life: 3000, });
                return;
            }
            console.log(this.empresaBase);
            console.log(this.empresa);
            axios
                .post("https://api-framework.contago.com.mx/api/Usuarios/Login", {
                    idUsuario: this.idUsuario,
                    nombre: this.nombreUsuario,
                    rol: this.rolUsuario,
                    empresa: this.empresa,
                    empresaBase: this.empresaBase,
                    firma: this.firma,
                    rfc: this.rfc,
                })
                .then((response) => {
                    console.log(response.data);
                    this.loadingInicio = 0
                    return response.data;
                })
                .then((data) => {
                    this.$store.dispatch("guardarToken", data.token);
                    this.$router.push({ name: "HomeView" });
                    this.nombre = "";
                    this.password = "";
                })
                .catch((err) => {
                    if (err.response.status == 500) {
                        this.$toast.add({
                            severity: "error",
                            summary: "Error",
                            detail: "Error",
                            life: 3000,
                        });
                        console.log(err.response.status);
                        this.loadingInicio = 0
                    }
                });
        },

        listaEmpresas(item) {
            axios
                .get(
                    "https://api-framework.contago.com.mx/api/Usuarios/Empresas/" +
                    item +
                    "/" +
                    "KIOSKO"
                )
                .then((response) => {
                    this.itemsEmpresa = response.data;
                    console.log(this.itemsEmpresa);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        handleOnComplete(value) {
            console.log("OTP completado: ", value);
            this.password = value;
            this.validarUsuario();
        },
        handleOnChange(value) {
            console.log("OTP cambio: ", value);
            this.itemsEmpresa = [];
        },
        handleClearInput() {
            this.$refs.otpInput.clearInput();
        },
    },
};
</script>
<style>
.contenedorboton {
    position: relative;
}

/* Botón flotante */
.boton-flotante {
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 50%;
    padding: 10px 20px;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.dropdown .p-dropdown-items-wrapper {
    max-height: 420px;
    max-width: 420px;
}

.padre {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.hijo {
    width: auto;
}

.otp-input {
    width: 45px;
    height: 45px;
    padding: 5px;
    margin: 12px;
    margin-right: 10px;
    font-size: 20px;
    border-radius: 4px;
    border: 2px solid rgba(0, 0, 0, 0.3);
    text-align: center;
    justify-content: center;
    align-items: center;
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
    margin: 0;
}

/* LOGIN */
.container {
    width: 100%;
    height: 150vh;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-container {
    display: flex;
    width: 450px;
    height: 460px;
    margin-top: 100px;
    background: #0000;
    /* box-shadow: 5px 4px 4px 4px rgba(0, 0, 0, 0.2); */
}

.register,
.login {
    flex: 1;
}

.register {
    position: relative;
    width: 20px;
}

.register h2 {
    color: #797979;
    margin-bottom: 30px;
}

.register input {
    width: 100%;
    padding: 5px;
    font-size: 16px;
    margin-bottom: 25px;
    border-top-style: none;
    border-left-style: none;
    border-right-style: none;
    border-bottom: 1px solid #a8a8a8;
    color: #333;
}

.register input:active,
.register input:focus {
    outline: none;
}

.register .submit {
    width: auto;
    padding: 10px;
    background: #f17108;
    color: #0000;
    font-size: 12px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.login {
    padding: 20px;
    width: 300px;
}

.login h2 {
    color: #fff;
    margin-bottom: 45px;
}

.login button i {
    position: absolute;
    top: 12px;
    left: 12px;
}

.login button {
    width: 100%;
    margin: 15px 0px;
    padding: 10px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    position: relative;
}

.login .fb {
    background: #3b5999;
    border-style: none;
    color: #fff;
}

.login .tw {
    background: #55acef;
    border-style: none;
    color: #fff;
}

.login .correo {
    background: #33d4ce;
    border-style: none;
    color: #fff;
}

@media only screen and (max-width: 540px) {
    .login-container {
        display: block;
    }

    .register,
    .login {
        flex: none;
    }

    .register::after {
        top: unset;
        bottom: -25px;
        left: 50%;
        transform: translateX(-50%) rotate(90deg);
    }
}
</style>

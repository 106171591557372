<template>
    <div>
        <div class="p-d-flex">
            <Toast />
        </div>
        <!-- DIALOG DEL PDF -->
        <Dialog :modal="true" :visible.sync="dialogPdf" :showHeader="false" position="center" class="stylos"
            :closable="false">
            <comprobante-pdf @cierraDialogPdf="closeDialogPdf"></comprobante-pdf>
        </Dialog>

        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: .5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>

        <div class="text-left mr-0 p-d-flex justify-center p-fluid grid formgrid">
            <div class="field mr-0 pr-0 col-12 text-center mt-12">
                <h1 style="background: #000000; color: white; font-size: 20px; font-family: arial;">
                    HISTORIAL DE FACTURAS
                </h1>
            </div>
        </div>
        <Card>
            <template #title>
                <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
                    <div class="col-12 md:col-4 text-right">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filtrosFacturas['global'].value" placeholder="Buscar" />
                        </span>
                    </div>
                    <div class="col-3 text-right">
                    </div>

                    <!-- FECHA-->
                    <div class="col-12 md:col-5 text-right">
                        <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
                            <div class="col-12 md:col-12 text-left">
                                <div class="p-inputgroup">
                                    <span class="p-float-label">
                                        <Calendar v-model="fechaI" dateFormat="dd-MM-yy" />
                                        <label>Fecha Inicial</label>
                                    </span>
                                    <span class="p-float-label">
                                        <Calendar v-model="fechaF" dateFormat="dd-MM-yy" />
                                        <label>Fecha Final</label>
                                    </span>
                                    <Button icon="pi pi-search" class="p-button-success pl-4 pr-4"
                                        @click="getHistorial()" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #content>
                <DataTable :filters.sync="filtrosFacturas" class="mt-1 p-datatable-sm" :value="itemsFacturas"
                    :scrollable="true" scrollHeight="800px" scrollDirection="both" :loading="loadingFacturas"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :rowsPerPageOptions="[5, 10, 25]"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Facturas." :paginator="true"
                    :rows="10">
                    <Column header="Acciones" :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'min-width': '5rem', }"
                        frozen>
                        <template #body="slotProps">
                            <Button @click="verPdfComprobante(slotProps.data)" icon="pi pi-file-pdf"
                                class="p-button-rounded" style="background:#E65100" v-tooltip.bottom="'PDF'" />
                        </template>
                    </Column>
                    <Column header="Serie/Folio" :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'font-size': '100%', 'background-color': '#eaf4ff' }">
                        <template #body="{ data }">
                            <span>{{ data.serie + ' - ' + data.folio }}</span>
                        </template>
                    </Column>
                    <Column field="fecha" header="Fecha" :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
                    </Column>
                    <Column field="rfcEmisor" header="RFC  Emisor"
                        :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
                    </Column>
                    <Column field="nombreEmisor" header="Nombre Emisor"
                        :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '90%' }">
                    </Column>
                    <Column field="rfcReceptor" header="RFC  Receptor"
                        :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
                    </Column>
                    <Column field="nombreReceptor" header="Nombre Receptor"
                        :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '90%' }">
                    </Column>
                    <Column field="subTotal" header="SubTotal"
                        :bodyStyle="{ 'justify-content': 'right', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '80px', 'font-size': '90%', 'text-align': 'right' }">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.subTotal) }}</span>
                        </template>
                    </Column>
                    <Column field="total" header="Total"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '80px', 'background-color': '#effaf1', 'font-size': '90%' }"
                        :bodyStyle="{ 'justify-content': 'right', overflow: 'visible' }">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.total) }}</span>
                        </template>
                    </Column>
                    <Column field="folioFiscal" header="Folio Fiscal"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '80px', 'font-size': '90%' }"
                        :bodyStyle="{ 'justify-content': 'right', overflow: 'visible' }">
                        <template #body="{ data }">
                            <span>{{ data.folioFiscal }}</span>
                        </template>
                    </Column>
                    <!-- <Column field="baseFactura" header="Guardado en"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '80px', 'font-size': '90%' }"
                        :bodyStyle="{ 'justify-content': 'right', overflow: 'visible' }">
                        <template #body="{ data }">
                            <span>{{ data.baseFactura }}</span>
                        </template>
                    </Column> -->
                </DataTable>
            </template>
        </Card>
    </div>
</template>
<script>
import { FilterMatchMode, FilterOperator } from "primevue/api/";

import moment from 'moment'
import axios from "axios";
import ComprobantePdf from "../Pdf/ComprobantePdf.vue";

import html2pdf from "html2pdf.js";
export default {
    components: {
        ComprobantePdf,
    },

    data() {
        return {
            loading: false,
            loadingMensaje: '',
            fechaI: new Date(),
            fechaF: new Date(),
            loadingFacturas: null,
            filtrosFacturas: {
                global: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                serie: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },], },
                folio: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },], },
                rfc: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },], },
                nombre: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },], },
            },
            dialogPdf: false
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        itemsFacturas() {
            return this.$store.state.listFacturaStore
        },
        empresaInicial() {
            return this.$store.state.empresaEmisoraStore.baseEmpresa
        }
    },
    created() {
    },
    methods: {

        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

        async getHistorial() {
            this.loadingFacturas = true;
            this.$store.state.listVentasStore = []
            try {
                let fI = moment(this.fechaI).format('YYYY-MM-DD HH:mm:ss')
                let fF = moment(this.fechaF).format('YYYY-MM-DD HH:mm:ss')
                let response = await axios.get('Comprobantes/GetHistorialKiosko/' + this.token.empresaBase + '/' + fI + '/' + fF);
                console.log(response)
                this.$store.state.listFacturaStore = response.data
                this.loadingFacturas = false;
            } catch (error) {
                console.log(error);
                this.loadingFacturas = false;
            }
        },

        formatDate(date) {
            moment.locale('es-mx')
            let fechaR = moment(date).format("DD-MMMM-yyyy");
            return fechaR;
        },

        async verPdfComprobante(item) {
            this.$store.state.empresaEmisoraStore.baseEmpresa = item.baseFactura
            console.log(item.baseFactura)
            this.loading = true;
            try {
                this.loadingMensaje = 'Consultando comprobante...'

                let response = await axios.get('Comprobantes/GetComprobanteFolioFiscal/' + item.baseFactura + '/' + item.folioFiscal);
                let _comprobante = response.data[0]
                console.log(_comprobante)
                this.$store.state.facturaStore = { ..._comprobante }
                this.loading = false
                this.dialogPdf = true;

            } catch (error) {
                console.log(error)
                this.loading = false
            }
        },
        closeDialogPdf() {
            this.dialogPdf = false

        },

    }
}
</script> 
<style>
.stylos .p-dialog {
    max-width: 850px;
    min-width: 850px;
}
</style>
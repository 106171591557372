<template>
  <div id="app">
    <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
      <ProgressBar mode="indeterminate" style="height: 0.5em" />
      <span>{{ loadingMensaje }}</span>
    </Dialog>
    <Toolbar id="border" class="pl-0 pt-2 pr-0 border" v-if="logueado" style="background: #241B5E;">
      <template #start>
        <i @click="inicio()" style="font-size: 25px; cursor: pointer" class="ml-4 mr-4 mdi mdi-home"></i>
        <h4>{{ $store.state.usuario.empresa }}</h4>
      </template>
      <template #end>
        <!-- <h4>{{ $store.state.usuario.rfc }}</h4> -->
        <div type="button" @click="toggle">
          <Avatar style="cursor: pointer" type="button" icon="pi pi-user" class="ml-5 mr-5" size="large" shape="circle"
            aria-haspopup="true" aria-controls="overlay_tmenu" />
        </div>
        <TieredMenu id="overlay_tmenu" ref="menu" :model="itemsSalir" :popup="true" />
      </template>
    </Toolbar>

    <Sidebar :visible.sync="visibleLeft" :showCloseIcon="false" :baseZIndex="1000">
      <div @click="inicio()" style="cursor: pointer">
        <img src="../src/assets/logo_contago.jpg" height="70" />
      </div>
      <PanelMenu :model="items" />
    </Sidebar>

    <div class="layout-main-container">
      <div class="layout-main">
        <router-view />
      </div>
    </div>
    <div id="footer" class="footer">
      <div class="bg-bluegray-900 text-gray-100 p-3 text-center">
        <a class="text-white" href="https://contago.com.mx/" target="_blank">
          <span class="underline font-bold">
            contago | 2023</span></a>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      loading: false,
      loadingMensaje: "",
      nombreUsuario: "",
      visibleLeft: false,
      itemsSalir: [
        {
          label: "Salir",
          icon: "pi pi-fw pi-power-off",
          command: (event) => {
            this.salir();
          },
        },
      ],

      items: [
        {
          label: "Inicio",
          icon: "pi pi-fw pi-home",
          to: "/",
          command: () => {
            this.visibleLeft = false;
          },
        },

        {
          label: "Empresas",
          icon: "pi pi-fw pi-building",
          to: "/ListaEmpresas",
          command: () => {
            this.visibleLeft = false;
          },
        },

        {
          label: "Historial",
          icon: "pi pi-fw pi-file",
          to: "/ListaHistorial",
          command: () => {
            this.visibleLeft = false;
          },
        }
      ],
    };
  },
  computed: {
    logueado() {
      return this.$store.state.usuario;
    },
    obtieneEmpresa() {
      return this.$store.state.usuario.empresa;
    },
    obtieneUsuario() {
      return this.$store.state.usuario.nombre;
    },
    esAdministrador() {
      return (
        this.$store.state.usuario &&
        this.$store.state.usuario.rol == "Administrador"
      );
    },
    Usuario() {
      return this.$store.state.usuario.rol;
    },
  },
  created() {
    this.$store.dispatch("autoLogin");
  },
  methods: {
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
    inicio() {
      this.$router.push({ name: "HomeView" }), (this.visibleLeft = false);
    },

    configuracion() {
    },
    async salir() {
      this.loading = true;
      this.loadingMensaje = "Cerrando Sesión...";
      let respuesta;
      try {
        respuesta = await axios.put(
          "https://api-framework.contago.com.mx/api/Usuarios/actualizaSesion/" +
          this.$store.state.usuario.nombre +
          "/INACTIVA"
        );
        this.$store.dispatch("salir");


        let empresa = {
          logo: {
            extencion: '',
            base64: '',
          },
          idEmpresa: 0,
          rfc: '',
          domicilioFiscal: '',
          nombre: '',
          regimenFiscal: {}
        }

      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
  },
};
</script>
<style>
.p-sidebar .p-sidebar-header {
  padding: 0rem;
}

.p-sidebar .p-sidebar-content {
  padding: 0rem;
}

.p-panelmenu .p-panelmenu-header>a {
  padding: 1.5rem;
  border: 0 none;
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
  font-weight: 400;
  border-radius: 4px;
  transition: none;
}

.p-panelmenu .p-panelmenu-header>a .p-panelmenu-icon {
  margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-header>a .p-menuitem-icon {
  margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-header>a:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled)>a:hover {
  background: #BBDEFB;
  border-color: transparent;
  color: rgba(0, 0, 0, 0.87);
}

.p-panelmenu .p-panelmenu-header.p-highlight {
  margin-bottom: 0;
}

.p-panelmenu .p-panelmenu-header.p-highlight>a {
  background: #BBDEFB;
  border-color: transparent;
  color: rgba(0, 0, 0, 0.87);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled)>a:hover {
  border-color: transparent;
  background: #BBDEFB;
  color: rgba(0, 0, 0, 0.87);
}

.p-panelmenu .p-panelmenu-content {
  padding: 0.5rem 0;
  border: 0 none;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 0;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  padding: 1rem 1rem;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0;
  transition: none;
  user-select: none;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
  margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
  background: #E3F2FD;

}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-panelmenu-icon {
  margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-submenu) {
  padding: 0 0 0 1rem;
}

.p-panelmenu .p-panelmenu-panel {
  margin-bottom: 0;
}

.p-panelmenu .p-panelmenu-panel .p-panelmenu-header>a {
  border-radius: 0;
}

.p-panelmenu .p-panelmenu-panel .p-panelmenu-content {
  border-radius: 0;
}

.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header>a {
  border-top: 0 none;
}

.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover>a,
.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-disabled).p-highlight:hover>a {
  border-top: 0 none;
}

.p-panelmenu .p-panelmenu-panel:first-child .p-panelmenu-header>a {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-header:not(.p-highlight)>a {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-content {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  margin: 0px;
  width: 100%;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

.border {
  /* border-top: 3px; */
  background-color: #241B5E;
  height: 60px;
}

.footer {
  position: fixed;
  left: 0px;
  bottom: 0px;
  height: 40px;
  width: 100%;
}

.p-toolbar {
  padding: 0.5rem;
  border-radius: 0px;
  margin: 0px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  margin: 0px;
  width: 100%;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}


.p-menu {
  padding: 0.5rem 0;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  /* border: 1px solid #e5e5e5; */
  border-radius: 4px;
  /* width: 12.5rem; */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.p-tieredmenu .p-menuitem-link {
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  padding: 0.75rem 1.25rem;
  color: #000000;
  border-radius: 0;
  transition: box-shadow 0.2s;
  user-select: none;
}

.p-tieredmenu .p-menuitem-text {
  line-height: 1;
  padding-left: 10px;
}

.layout-sidebar {
  position: fixed;
  left: 0;
  top: 70px;
  height: calc(100% - 70px);
  background-color: var(--surface-overlay);
  width: 250px;
  border-right: 1px solid var(--surface-border);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: -webkit-transform .4s cubic-bezier(.05, .74, .2, .99);
  transition: -webkit-transform .4s cubic-bezier(.05, .74, .2, .99);
  transition: transform .4s cubic-bezier(.05, .74, .2, .99);
  transition: transform .4s cubic-bezier(.05, .74, .2, .99), -webkit-transform .4s cubic-bezier(.05, .74, .2, .99);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.layout-content .content-section.introduction {
  color: var(--text-color);
  padding-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: top;
  -ms-flex-align: top;
  align-items: top;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
</style>

<template>
  <ValidacionTicket></ValidacionTicket>
</template>

<script>
// @ is an alias to /src
import ValidacionTicket from '@/components/Validacion/ValidacionTicket.vue'

export default {
  name: 'HomeView',
  components: {
    ValidacionTicket
  }
}
</script>

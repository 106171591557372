import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '../store/index'
import Login from '../views/Login.vue'
import ValidacionTicket from '../components/Validacion/ValidacionTicket.vue'
import ListaEmpresas from '../components/Empresas/ListaEmpresas.vue'
import ListaHistorial from '../components/Historial/ListaHistorial.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/Login', name: 'Login', component: Login, meta: { libre: true } },
  { path: '/', name: 'HomeView', component: HomeView, meta: { Administrador: true } },
  {
    path: '/about', name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  { path: '/ValidacionTicket', name: 'ValidacionTicket', component: ValidacionTicket, meta: { Administrador: true } },
  { path: '/ListaEmpresas', name: 'ListaEmpresas', component: ListaEmpresas, meta: { Administrador: true } },
  { path: '/ListaHistorial', name: 'ListaHistorial', component: ListaHistorial, meta: { Administrador: true } },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.libre)) {
    next()
  } else if (store.state.usuario && store.state.usuario.rol == 'Administrador') {
    if (to.matched.some(record => record.meta.Administrador)) {
      next()
    }
  } else {
    next({
      name: 'Login'
    })
  }
})
export default router

<template>
    <div class="mb-8">

        <div class="p-d-flex">
            <Toast />
        </div>
        <Dialog header=" " :visible.sync="displayPosition" position="bottomright" :modal="true">
            <iframe style="width: 400px; height: 260px;" src="https://www.youtube.com/embed/dxWl6QSOb3c?autoplay=1"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe>
        </Dialog>
        <!-- DIALOG DEL PDF -->
        <Dialog :visible.sync="dialogPdf" :style="{ width: '850px' }" :closable="false">
            <comprobante-pdf @cierraDialogPdf="closeDialogPdf"></comprobante-pdf>
        </Dialog>

        <div class="pt-8 mt-8" v-if="loadingTimbrado == 1">
            <ProgressSpinner class="pt-8 mt-8" style="vertical-align:middle; text-align: center;" />
        </div>

        <div class="text-right pt-4 pr-5">
            <Button icon="mdi mdi-youtube" @click="displayPosition = true"
                style="font-size: 26px; width: 40px;height: 40px;" class="p-button-rounded mr-1"
                v-tooltip.bottom="'Tutorial'" />
            <Button icon="mdi mdi-information" @click="toggle" style="font-size: 26px; width: 40px;height: 40px;"
                class="p-button-rounded" v-tooltip.bottom="'Ayuda'" />
        </div>

        <OverlayPanel ref="op" appendTo="body" class="over" :showCloseIcon="false" id="overlay_panel">
            <Message :closable="false" severity="info">Para soporte técnico o más información, comunícate con nosotros al
                teléfono (222 622 6540) o escríbenos al correo (contago.ventas@gmail.com).</Message>
        </OverlayPanel>

        <div class="mt-4 text-center">
            <img src="../../../src/assets/contago_kiosco.jpg" class="responsive-image">
        </div>

        <template v-if="loadingTimbrado == 2">
            <div style="display: flex; justify-content: center; align-items: center;">
                <div class="mx-4 p-fluid grid formgrid" style="width: 500px;">
                    <div class=" col-6 mb-4">
                        <span @click="$router.push({ name: 'ListaEmpresas' })"
                            class="p-3 shadow-7 mb-3 inline-block surface-card"
                            style="border-radius: 10px ;  cursor: pointer;">
                            <i class="pi pi-desktop text-4xl text--500" style="color: #241B5E;"></i>
                        </span>
                        <div class="text-900 text-x mb-3 font-medium">Empresas - Clientes</div>
                    </div>
                    <div class="col-6 mb-4">
                        <span class="p-3 shadow-7 mb-3 inline-block surface-card"
                            style="border-radius: 10px;  cursor: pointer;"
                            @click="$router.push({ name: 'ListaHistorial' })">
                            <i class="pi pi-history text-4xl text--500" style="color: #241B5E;"></i>
                        </span>
                        <div class="text-900 text-x mb-3 font-medium">Historial Facturas</div>
                    </div>
                </div>
            </div>

            <div class="text-left mr-0 p-d-flex justify-center p-fluid grid formgrid">
                <div class=" px-4 field mr-0 pr-0 col-12 text-center mt-12">
                    <h1 style="background: #241B5E; color: white; font-size: 16px; font-family: arial;">
                        EMPRESA A CONSULTAR
                    </h1>
                </div>
            </div>
            <div class="text-left px-4 mr-0 p-d-flex justify-center p-fluid grid formgrid">
                <div class="field col-12 md:col-12">
                    <span class="p-float-label">
                        <AutoComplete style="text-align: left" class="auto-complete"
                            v-model="$store.state.empresaEmisoraStore" :suggestions="filtroEmpresa"
                            @complete="searchEmpresa($event)" field="nombreComercial">
                        </AutoComplete>
                        <label>Empresa a Consultar</label>
                    </span>
                    <span hidden style="color:black">{{ empresaInicial }}</span>
                </div>
            </div>
            <div class="text-left mr-0 mt-4 p-d-flex justify-center p-fluid grid formgrid">
                <div class="field px-4 mr-0 pr-0 col-12 text-center mt-12">
                    <h1 style="background: #241B5E; color: white; font-size: 16px; font-family: arial;">
                        VALIDACIÓN DE TICKET
                    </h1>
                </div>
            </div>
            <div class="text-left px-4 mr-0  p-d-flex justify-center p-fluid grid formgrid">

                <!-- <div class="field col-12 md:col-3">
                    <span class="p-float-label">
                        <InputText type="text" v-model="valida.serie" class="p-inputtext-sm" />
                        <label>Serie</label>
                    </span>
                </div>-->

                <!-- <div class="field col-12 md:col-3">
                    <span class="p-float-label">
                        <InputText type="text" v-model="valida.folio" class="p-inputtext-sm" />
                        <label>Folio</label>
                    </span>
                </div> -->

                <div class="field col-12 md:col-3">
                    <span class="p-float-label">
                        <Calendar dateFormat="dd-MM-yy" v-model="fechaI" class="p-inputtext-sm" :showSeconds="true"
                            :showTime="true" />
                        <label>Fecha</label>
                    </span>
                </div>
                <div class="field col-12 md:col-3">
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <InputNumber mode="decimal" class="p-inputtext-sm" @keyup.enter="getValidaVenta()"
                                v-model="valida.total" locale="en-US" :minFractionDigits="2" />
                            <label>Total</label>
                        </span>
                        <Button icon="pi pi-check" class="p-button-success" v-tooltip.bottom="'Click para Validar'"
                            @click="getValidaVenta()" />
                    </div>
                </div>
            </div>
            <template v-if="loadingValidarCompra == 1">
                <div class="text-left mr-0 p-d-flex justify-center p-fluid grid formgrid">
                    <div class="field px-4 mr-0 pr-0 col-12 text-center mt-12">
                        <h1 style="background: #241B5E; color: white; font-size: 16px; font-family: arial;">
                            DATOS DE LA FACTURA
                        </h1>
                    </div>
                </div>

                <div class="p-d-flex px-4 mr-0 justify-center p-fluid grid formgrid col-12">
                    <!-- SERIE -->
                    <!-- <div class="field col-12 md:col-4">
                        <span class="p-float-label">
                            <InputText readonly v-model="factura.serie" />
                            <label>Serie</label>
                        </span>
                    </div> -->
                    <!-- FOLIO -->
                    <!-- <div class="field col-12 md:col-4">
                        <span class="p-float-label">
                            <InputText readonly v-model="factura.folio" type="text" />
                            <label>Folio</label>
                        </span>
                    </div> -->
                    <!-- FORMA DE PAGO -->
                    <div class="field col-12 md:col-8">
                        <span class="p-float-label">
                            <InputText readonly v-model="factura.formaPago.formaPago" type="text" />
                            <label>Forma de Pago</label>
                        </span>
                    </div>

                    <!-- FECHA -->
                    <div class="field col-12 md:col-4">
                        <span class="p-float-label">
                            <Calendar v-model="fechaTimbrado" dateFormat="dd-MM-yy" :showTime="true" hourFormat="12" />
                            <label>Fecha de Factura</label>
                        </span>
                    </div>
                    <!-- CLIENTE -->
                    <div class="field col-12">
                        <span class="p-float-label">
                            <AutoComplete style="text-align: left" class="auto-complete p-invalid" v-model="receptor"
                                :suggestions="filteredReceptor" @complete="searchReceptor($event)" field="empresa">
                            </AutoComplete>
                            <label>Cliente</label>
                        </span>
                        <span hidden style="color: black;">{{ receptor }}</span>
                        <template v-if="this.empresasReceptoras.length == 0">
                            <Message :closable="false" severity="info">No tienes dado de alta ningún Cliente Receptor para
                                tu
                                factura, deberás de registrarlo en el botón 'Empresas - Clientes'</Message>
                        </template>

                    </div>
                    <div class="field col-12 mb-5">
                        <DataTable style="width:100%;" :header="false" class="page-break-inside p-datatable-sm"
                            :value="factura.conceptos" responsiveLayout="scroll">
                            <Column field="descripcion" header="Descripción" class="flex"
                                :styles="{ 'flex-grow': '1', 'flex-basis': '150px', 'font-size': '100%' }">
                                <template #body="{ data }">
                                    <h4>{{ data.descripcion }}</h4>
                                    <div>{{ data.claveProdServ.claveProdServ }}</div>
                                </template>
                            </Column>
                            <Column field="cantidad" header="Cantidad"
                                :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '100%' }">
                            </Column>
                            <Column field="claveUnidad.claveUnidad" header="Clave Unidad"
                                :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '100%' }">
                            </Column>
                            <Column field="valorUnitario" header="Valor Unitario"
                                :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '100%', 'text-align': 'right' }">
                                <template #body="{ data }">
                                    <span>{{ formatCurrency(data.valorUnitario) }}</span>
                                </template>
                            </Column>
                            <Column field="importe" header="Importe"
                                :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '100%', 'text-align': 'right' }">
                                <template #body="{ data }">
                                    <span>{{ formatCurrency(data.importe) }}</span>
                                </template>
                            </Column>
                        </DataTable>
                    </div>
                    <div class="field col-12 md:col-3">
                        <span class="p-float-label">
                            <InputNumber readonly disabled v-model="factura.subTotal" mode="decimal" :minFractionDigits="2"
                                locale="en-US" />
                            <label>SubTotal</label>
                        </span>
                    </div>
                    <div class="field col-12 md:col-3">
                        <span class="p-float-label">
                            <InputNumber readonly disabled v-model="factura.total" mode="decimal" :minFractionDigits="2"
                                locale="en-US" />
                            <label>Total</label>
                        </span>
                    </div>
                    <div class="field col-12 md:col-2">
                    </div>
                    <div class="field col-12 md:col-2">
                        <Button label="Cancelar" class="p-button-raised" @click="cancelar()" />
                    </div>
                    <div class="field col-12 md:col-2">
                        <Button label="Timbrar" class="p-button-raised" @click="postFactura()" />
                        <span hidden style="color:black">{{ token }}</span>
                    </div>
                </div>
            </template>

            <div v-if="loadingValidarCompra == 2"
                class="p-d-flex px-4 mr-0 pt-8 mt-8 justify-center p-fluid grid formgrid col-12">
                <ProgressSpinner style="vertical-align:middle;" />
            </div>
        </template>
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import ComprobantePdf from "../Pdf/ComprobantePdf.vue";

export default {
    components: {
        ComprobantePdf,
    },
    data() {
        return {
            fechaTimbrado: new Date(),
            filteredReceptor: null,
            lista: [],
            cliente: {
                cliente: ''
            },
            itemVenta: [],
            valida: {
                serie: '',
                folio: '',
                total: 0.00,
                fecha: null
            },
            fechaI: new Date(),
            dialogEmpresaInicial: false,

            filtroEmpresa: null,
            empresasKiosko: [],
            loadingValidarCompra: 3,

            dialogPdf: false,
            receptor: { empresa: '' },

            loadingTimbrado: 2,
            datosEmpresa: {},
            displayPosition: false
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        factura() {
            return this.$store.state.facturaStore
        },
        empresasReceptoras() {
            return this.$store.state.listaEmpresasStore;
        },
        empresaInicial() {
            return this.$store.state.empresaEmisoraStore
        }

    },
    created() {
        this.GetEmpresasKiosco();
        this.GetListaEmpresas();
    },
    mounted() { },
    methods: {
        toggle(event) {
            this.$refs.op.toggle(event);
        },
        searchReceptor(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredReceptor = [...this.empresasReceptoras];
                }
                else {
                    this.filteredReceptor = this.empresasReceptoras.filter((country) => {
                        return country.empresa.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
        },
        searchEmpresa(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filtroEmpresa = [...this.empresasKiosko];
                }
                else {
                    this.filtroEmpresa = this.empresasKiosko.filter((country) => {
                        return country.nombreComercial.toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
        },
        async GetEmpresasKiosco() {
            try {
                let result = await axios.get('Empresas/GetEmpresaKiosko');
                this.empresasKiosko = result.data;
            } catch (error) {
                console.log(error)
            }
        },

        async getValidaVenta() {
            if (this.empresaInicial.empresa == "") {
                this.$toast.add({ severity: "error", summary: "Error", detail: "Seleccione una Empresa para poder validar la compra.", life: 3000, });
                return;
            }

            this.loadingValidarCompra = 2
            this.valida.fecha = moment(this.fechaI).format('YYYY-MM-DD HH:mm:ss')

            try {
                let response = await axios.post('Ventas/GetValidaVenta/' + this.empresaInicial.baseEmpresa + '/' + this.empresaInicial.rfc, this.valida);
                console.log(response.data)
                console.log(response.data.serie, response.data.folio)
                response.data.serie = ''
                response.data.folio = ''
                this.$store.state.facturaStore = { ...response.data }

                let cfdiRelacionados = [
                    {
                        tipoRelacion: {},
                        cfdiRelacionados: [],
                    }
                ]
                this.$store.state.facturaStore.cfdiRelacionados = cfdiRelacionados
                this.loadingValidarCompra = 1

            } catch (error) {
                console.log(error);
                this.$toast.add({ severity: 'error', detail: 'Error:' + error.response.data, life: 5000 });
                this.loadingValidarCompra = 3

            }
        },

        //OBTENEMOS LAS EMPRESAS QUE CUENTAN CON SISTEMA ERP
        async GetListaEmpresas() {
            try {
                let result = await axios.get('Empresas/GetListaEmpresas/' + this.token.empresaBase);
                this.$store.state.listaEmpresasStore = result.data;
            } catch (error) {
                console.log(error)
            }
        },
        //OBTENEMOS LOS DATOS DE LA EMPRESA EMISORA
        async GetEmpresa() {
            try {
                let response = await axios.get('Empresas/GetEmpresa/' + this.empresaInicial.baseEmpresa);
                console.log('empresa', response.data)
                this.datosEmpresa = { ...response.data }
            } catch (error) {
                console.log(error);
            }
        },
        async postFactura() {
            await this.GetEmpresa()
            try {
                if (this.receptor.empresa == "") {
                    this.$toast.add({ severity: "error", summary: "Error", detail: "Seleccione un Cliente a Facturar", life: 3000, });
                    return;
                }
                this.loadingTimbrado = 1

                let empresa = {
                    rfc: this.datosEmpresa.rfc,
                    nombre: this.datosEmpresa.nombre,
                    domicilioFiscal: this.datosEmpresa.domicilioFiscal,
                    regimenFiscal: this.datosEmpresa.regimenFiscal
                }

                let cliente = {
                    idCliente: this.receptor.idEmpresa,
                    rfc: this.receptor.rfc,
                    nombre: this.receptor.nombreEmpresa,
                    domicilioFiscal: this.receptor.codigoPostal,
                    residenciaFiscal: {},
                    regimenFiscal: this.receptor.regimenFiscal,
                    usoCfdi: this.receptor.usoCfdi,
                    numRegIdTrib: '',
                    direccion: '',
                    estatus: '',
                    cliente: this.receptor.empresa,
                    correos: []
                }
                console.log(cliente)

                let comprobante = { ...this.factura }
                comprobante.cliente = cliente
                comprobante.empresa = empresa
                comprobante.rfc = this.factura.cliente.rfc
                comprobante.nombre = this.factura.cliente.nombre
                comprobante.fecha = moment(this.fechaTimbrado).format('YYYY-MM-DD HH:mm:ss')
                comprobante.usuarioCreador = this.token.nombre
                comprobante.tipoComprobanteInterno = 'Factura'
                let response = await axios.post('Comprobantes/PostTimbraComprobanteKiosco/' + this.empresaInicial.baseEmpresa + '/' + this.token.empresaBase, comprobante)
                console.log(response)
                //ASIGNAMOS VALORES
                comprobante.timbreFiscalDigital = { ...response.data };
                comprobante.folio = response.data.folio
                comprobante.folioFiscal = response.data.folioFiscal
                comprobante.rfc = comprobante.cliente.rfc
                comprobante.nombre = comprobante.cliente.nombre
                comprobante.estatus = 'Vigente'
                comprobante.baseFactura = this.empresaInicial.baseEmpresa
                comprobante.rfcEmisor = comprobante.empresa.rfc
                comprobante.rfcReceptor = comprobante.cliente.rfc
                comprobante.nombreEmisor = comprobante.empresa.nombre
                comprobante.nombreReceptor = comprobante.cliente.nombre
                //ASIGNAMOS VALORES PARA EL PDF
                this.$store.state.facturaStore.timbreFiscalDigital = { ...response.data };
                this.$store.state.facturaStore.folio = response.data.folio
                this.$store.state.facturaStore.folioFiscal = response.data.folioFiscal
                this.$store.state.facturaStore.cliente = comprobante.cliente
                this.$store.state.facturaStore.rfc = comprobante.cliente.rfc
                this.$store.state.facturaStore.nombre = comprobante.cliente.nombre
                this.$store.state.facturaStore.baseFactura = this.empresaInicial.baseEmpresa
                this.$store.state.facturaStore.estatus = 'Vigente'

                this.$store.state.listFacturaStore.push(comprobante);
                this.$store.state.facturaStore.idFactura = -1;
                let array = this.$store.state.listFacturaStore;
                this.$store.state.listFacturaStore = array.sort(((a, b) => b.folio - a.folio))

                this.$toast.add({ severity: 'success', detail: 'Comprobante timbrado con éxito', life: 5000 });
                this.dialogPdf = true;

                this.loadingTimbrado = 2
                this.loadingValidarCompra = 3

                this.valida = {
                    serie: '',
                    folio: '',
                    total: 0.00,
                    fecha: new Date()
                }

            } catch (error) {
                console.log(error)
                this.$toast.add({ severity: 'error', detail: 'Error al timbrar:' + error.response.data, life: 5000 });
                this.loadingTimbrado = 2
            }
        },
        formatDate(date) {
            moment.locale('es-mx')
            let fechaR = moment(date).format("DD-MMMM-yyyy");
            return fechaR;
        },
        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },
        closeDialogPdf() {
            this.dialogPdf = false
        },
        cancelar() {
            location.reload();
        }
    }
}
</script>
<style>
.responsive-image {
    max-width: 100%;
    max-height: 160px;
}

.p-overlaypanel .p-overlaypanel-content {
    padding-top: 2px;
    padding-right: 8px;
    padding-left: 8px;
    padding-bottom: 2px;
}
</style>
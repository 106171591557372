<template>
    <div>
        <!-- DIALOG PARA DAR DE ALTA EMPRESAS -->
        <Dialog :visible.sync="dialogEmpresas" :style="{ width: '1200px' }" position="top" :modal="false" :closable="false">
            <Empresa @cierraVentana="dialogEmpresas = false"></Empresa>
        </Dialog>

        <!-- DIALOG PARA ELIMINAR CLIENTES -->
        <Dialog :visible.sync="dialogDelete" :styles="{ width: '450px' }" :modal="true" :closeOnEscape="true"
            :closable="false">
            <div class="flex justify-content-between flex-wrap pb-2">
                <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined"
                    @click="dialogDelete = false" />
                Eliminar
                <Button icon="pi pi-check" class="p-button-rounded p-button-success p-button-outlined"
                    @click="confirmDelete()" />
            </div>
            <hr size="7" color="#ED6421" />
            <br />
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span v-if="editedEmpresa">Esta seguro de borrar la empresa
                    <b>{{ editedEmpresa.rfc }} | </b>
                    <b>{{ editedEmpresa.nombreEmpresa }}</b>?</span>
            </div>
        </Dialog>

        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: 0.5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>

        <!-- LISTA DE EMPRESAS -->
        <div class="text-right mr-0 p-d-flex justify-center p-fluid grid formgrid">
            <div class="field mr-0 pr-0 col-12 text-center mt-12">
                <h1 style="background: black; color: white; font-size: 20px; font-family: arial;">
                    EMPRESAS/CLIENTES
                </h1>
            </div>
        </div>

        <Card>
            <template #title>
                <div class="text-right mr-0 p-d-flex justify-center p-fluid grid formgrid">
                    <div class="col-7 text-right">
                    </div>
                    <div class="col-12 md:col-5 text-right">
                        <div class="p-inputgroup">
                            <InputText v-model="filtrosEmpresas['global'].value" placeholder="Buscar" />
                            <Button v-tooltip.bottom="'Actualizar'" @click="GetListaEmpresas()" icon="pi pi-sync"
                                class="p-button-raised p-button-success pl-4 pr-4" />
                            <Button @click="nuevaEmpresa()" icon="pi pi-plus" v-tooltip.bottom="'Nuevo'"
                                class="pl-4 pr-4 p-button-raised p-button-info" />
                        </div>
                    </div>
                </div>
            </template>
            <template #content>
                <DataTable :filters.sync="filtrosEmpresas" class="mt-1 p-datatable-sm" :value="itemsEmpresas"
                    :scrollable="true" scrollHeight="800px" scrollDirection="both" :loading="loadingEmpresas"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :rowsPerPageOptions="[5, 10, 25]"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Empresas." :paginator="true"
                    :rows="10">
                    <Column field="rfc" header="RFC" :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
                    </Column>
                    <Column field="nombreEmpresa" header="Nombre"
                        :bodyStyle="{ 'justify-content': 'left', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
                    </Column>
                    <Column field="codigoPostal" header="Codigo Postal"
                        :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
                    </Column>
                    <Column field="regimenFiscal.regimenFiscal" header="Régimen Fiscal"
                        :bodyStyle="{ 'justify-content': 'left', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
                    </Column>
                    <Column field="usoCfdi.usoCfdi" header="Uso CFDI"
                        :bodyStyle="{ 'justify-content': 'left', overflow: 'visible' }"
                        :styles="{ 'flex-grow': '1', 'flex-basis': '100px', 'font-size': '90%' }">
                    </Column>
                    <Column :exportable="false" :styles="{ 'min-width': '8rem' }" header="Acciones"
                        :bodyStyle="{ 'text-align': 'center', overflow: 'visible' }">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                                @click="editarEmpresa(slotProps.data)" v-tooltip.bottom="'Editar'" />
                            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                                @click="openDialogDelete(slotProps.data)" v-tooltip.bottom="'Eliminar'" />
                        </template>
                    </Column>
                </DataTable>
            </template>
        </Card>
    </div>
</template>

<script>
import { FilterMatchMode, FilterOperator } from "primevue/api/";
import Empresa from './Empresa.vue';
import axios from 'axios'
import moment from 'moment'
export default {
    components: { Empresa },
    data() {
        return {
            dialogEmpresas: null,
            listaEmpresas: [],
            filtrosEmpresas: {
                global: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            },
            loadingEmpresas: null,

            dialogDelete: false,
            editedIndexEmpresa: -1,
            editedEmpresa: {},

            loadingMensaje: "",
            loading: false,
        }
    },
    computed: {

        token() {
            return this.$store.state.usuario;
        },
        itemsEmpresas() {
            return this.$store.state.listaEmpresasStore;
        }
    },
    created() {
        this.GetRegimenFiscal();
        this.GetUsoCfdi();
        this.GetListaEmpresas()

    },
    mounted() { },
    methods: {
        async GetListaEmpresas() {
            this.loadingEmpresas = true
            try {
                let result = await axios.get('Empresas/GetListaEmpresas/' + this.token.empresaBase);
                this.$store.state.listaEmpresasStore = result.data;
                this.loadingEmpresas = false

            } catch (error) {
                console.log(error)
                this.loadingEmpresas = false

            }
        },
        async GetRegimenFiscal() {
            try {
                let response = await axios.get("CatalogosSat/GetRegimenFiscal/");
                console.log(response.data)
                this.$store.state.listRegimenFiscalStore = response.data;

            } catch (error) {
                console.log(error);
            }
        },
        async GetUsoCfdi() {
            this.loadingMensaje = 'Consultando Uso de CFDI...'
            if (this.$store.state.usoCfdiStore.length != 0) {
                return;
            }
            try {
                let response = await axios.get("CatalogosSat/GetUsoCfdi/");
                this.$store.state.usoCfdiStore = response.data;
            } catch (error) {
                console.log(error);
            }
        },
        nuevaEmpresa() {
            let nuevoEmpresa = {
                idEmpresa: 0,
                rfc: '',
                nombreEmpresa: '',
                empresa: '',
                codigoPostal: '',
                regimenFiscal: { regimenFiscal: '' },
                usoCfdi: { usoCfdi: '' },
                correos: []
            }
            if (this.$store.state.empresaStore.idEmpresa == -1) {
                this.$store.state.empresaStore = { ...nuevoEmpresa }
            }
            this.dialogEmpresas = true
        },
        editarEmpresa(item) {
            console.log(item)
            this.$store.state.empresaStore = { ...item };
            this.dialogEmpresas = true;
        },

        openDialogDelete(item) {
            this.editedIndexEmpresa = this.itemsEmpresas.indexOf(item);
            this.editedEmpresa = { ...item };
            this.dialogDelete = true;
            console.log(this.editedEmpresa)
        },

        async confirmDelete() {
            this.loading = true;
            this.loadingMensaje = "Procesando soliciud, espere...";
            try {
                let response = await axios.put("Empresas/PutEmpresaEstatus/" + this.token.empresaBase + '/' + this.editedEmpresa.idEmpresa);
                this.itemsEmpresas.splice(this.editedIndexEmpresa, 1);
                this.dialogDelete = false;
                this.loading = false;
                this.$toast.add({ severity: "success", detail: "Empresa eliminado con éxito.", life: 3000, });
            } catch (error) {
                console.log(error)
                this.$toast.add({
                    severity: "error", detail: "Error al eliminar, intente nuevamente.", life: 3000,
                });
                this.loading = false;
            }
        },
    }
}
</script>
<style>
.p-dialog .p-dialog-content {
    overflow-y: visible !important;
}

.p-dialog-mask {
    position: absolute;
    pointer-events: auto;
    /* (optional, doesn't cover whole area when scroll presents) */
}
</style>